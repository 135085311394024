import React from 'react'
import Helmet from 'react-helmet'

import { FaChevronRight } from 'react-icons/fa'

import { Link, graphql } from 'gatsby'

import Layout from '../layouts'
import { parisMetroPath } from '../path'


const MetroList = ({ data }) => (
  <Layout>
    <section className="section">
      <div className="container">
        <Helmet title="Les bars les plus sympas à chaque métro" />
        <h1 className="title">
          Les bars les plus sympas pour chaque métro
        </h1>
        <div className="content">
          <p>
            MisterGoodBeer ne paie pas ton navigo mais peut t'emmener vers le meilleur bar à la
            sortie du métro.
          </p>
          <h2>A quelle station es-tu sorti?</h2>
          <ul>
            {data.allMetrosParisJson.edges
              .map((metro) => metro.node)
              .map((metro) => (
                <li key={metro.code}>
                  <Link to={parisMetroPath(metro)}>
                    {metro.name}
                    <span className="icon is-large">
                      <FaChevronRight />
                    </span>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </section>
  </Layout>
)

export default MetroList


export const pageQuery = graphql`
  query ParisMetros {
    allMetrosParisJson (
      sort: {
        fields: [name],
      }
    ){
      edges {
        node {
          lines
          name
          coordinates {
            latitude
            longitude
          }
          code
        }
      }
    } 
  }
`
